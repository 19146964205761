import axios from "axios";
import { replace } from "react-router-redux";
import { createFlash } from "./flasher";

const baseAPI = process.env.REACT_APP_BASE_API;

export const login = (credential) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN_PENDING",
    });
    return axios({
      method: "post",
      data: { ...credential },
      url: `${baseAPI}/login`,
      // url: 'https://reqres.in/api/login'
    })
      .then((x) => {
        dispatch({
          type: "LOGIN_FULFILLED",
          payload: x.data,
        });
        dispatch(
          createFlash({
            id: Date.now(),
            type: "success",
            message: `Welcome`,
          })
        );
        dispatch(replace(credential.redirect || "/"));
      })
      .catch((e) => {
        dispatch(
          createFlash({
            id: Date.now(),
            type: "danger",
            message: e.message,
          })
        );
        dispatch({
          type: "LOGIN_REJECTED",
          payload: e,
        });
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    window.localStorage.removeItem("certState");

    dispatch({
      type: "LOGOUT_FULFILLED",
    });
    dispatch(
      createFlash({
        id: Date.now(),
        type: "success",
        message: `Goodbye`,
      })
    );
    dispatch(replace("/login"));
  };
};

export const register = (credential) => {
  return (dispatch) => {
    dispatch({
      type: "REGISTER_PENDING",
    });
    return axios({
      method: "post",
      data: { ...credential },
      url: `${baseAPI}/register`,
    })
      .then((x) => {
        dispatch({
          type: "REGISTER_FULFILLED",
          payload: x.data,
        });
        dispatch(
          createFlash({
            id: Date.now(),
            type: "success",
            message: `Welcome, please complete your profile`,
          })
        );
        dispatch(replace(`/users/${x.data.id}`));
      })
      .catch((e) => {
        dispatch({
          type: "REGISTER_REJECTED",
          payload: e,
        });
      });
  };
};

export const requestResetPassword = (credential) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_RESET_PASSWORD_PENDING",
    });
    return axios({
      method: "post",
      data: { ...credential },
      url: `${baseAPI}/forgot-password`,
    })
      .then((x) => {
        dispatch({
          type: "REQUEST_RESET_PASSWORD_FULFILLED",
          payload: x.data,
        });
        dispatch(
          createFlash({
            id: Date.now(),
            type: "success",
            message: `${x.data.message}`,
          })
        );
      })
      .catch((e) => {
        dispatch(
          createFlash({
            id: Date.now(),
            type: "danger",
            message: e.message,
          })
        );
        dispatch({
          type: "REQUEST_RESET_PASSWORD_REJECTED",
          payload: e,
        });
      });
  };
};

export const resetPassword = (credential) => {
  return (dispatch) => {
    dispatch({
      type: "RESET_PASSWORD_PENDING",
    });
    return axios({
      method: "post",
      data: { ...credential },
      url: `${baseAPI}/reset-password`,
    })
      .then((x) => {
        dispatch({
          type: "RESET_PASSWORD_FULFILLED",
          payload: x.data,
        });
        dispatch(
          createFlash({
            id: Date.now(),
            type: "success",
            message: `${x.data.message}`,
          })
        );
        dispatch(replace("/login?newPassword=1"));
      })
      .catch((e) => {
        dispatch(
          createFlash({
            id: Date.now(),
            type: "danger",
            message: `${e.message}`,
          })
        );
        dispatch({
          type: "RESET_PASSWORD_REJECTED",
          payload: e,
        });
      });
  };
};

const advanceSchedulingApi = process.env.REACT_APP_ADVANCE_SCHEDULING_API;

export const useExchangeToken = ({ token, userId }) => () => {
  axios
    .post(`${advanceSchedulingApi}/auth/exchange`, undefined, {
      headers: {
        Authorization: token ? `Bearer ${btoa(token)}` : undefined,
        "User-ID": userId ? btoa(`${userId}`) : undefined,
      },
    })
    .then((res) => {
      if (res.data.data.redirectURL) {
        window.open(
          `${res.data.data?.redirectURL}?access=${JSON.stringify(
            res.data.data
          )}`,
          "_blank"
        );
      }
    })
    .catch((err) => {
      // toast.danger(err.response?.data.error?.message || "Something went wrong");
      console.log(
        err?.response?.data?.error?.message || "Something went wrong"
      );
    });
};
