import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Logo from '../img/TUEV-NORD_Logo_Electric-Blue_sRGB.jpg'

import { login, requestResetPassword, resetPassword } from '../actions/auth.js'
import { asQueryObject } from '../const/helper'

import { LoginForm, ResetPasswordForm, RequestResetPasswordForm } from '../components/Login/LoginForm'
import Outside from './Outside'
import './Login.css'

export class Login extends React.Component {
  constructor (props) {
    super(props)
    const token = props.location.search.replace('?token=', '')
    this.state = {
      action: props.location.search.indexOf('token=') > -1 ? 'resetPassword' : 'login',
      token: props.location.search.indexOf('token=') > -1 ? token : null
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.location.search) {
      const search = asQueryObject(nextProps.location.search)

      if (search.newPassword === '1') {
        this.setState({
          token: null,
          action: 'login'
        })
      }
    }
  }

  handleLogin (e) {
    if (e.username.indexOf('>>') > 0) {
      const usernames = e.username.split('>>');
      e.username = usernames[0];
      e.target_username = usernames[1];
    }
    const redirect = this.props.location.state && this.props.location.state.from ? this.props.location.state.from : null
    return this.props.login({ ...e, redirect })
  }

  handleRequestPasswordReset (e) {
    return this.props.requestResetPassword({ ...e })
  }

  handlePasswordReset (e) {
    return this.props.resetPassword({ ...e, token: this.state.token })
  }

  toggleAction (action) {
    this.setState({ action })
  }

  render () {
    const { action, token } = this.state

    return (
      <Outside>
        <Helmet>
          <title>Welcome to SIM Cert</title>
        </Helmet>
        <div className='wrapper full-page-wrapper page-auth page-login text-center'>
          <div className='unsplash-image'></div>
          <div className='inner-page'>
            <div className='logo'>
              <h3><img alt='TÜV NORD INDONESIA' src={Logo} /></h3>
              <p className='text-grey'>Welcome back! Please login to your account</p>
            </div>
            <div className='login-box center-block'>
              { action === 'login' &&
                <LoginForm
                  onSubmit={this.handleLogin.bind(this)}
                />
              }
              { action === 'requestPasswordReset' &&
                <RequestResetPasswordForm
                  onSubmit={this.handleRequestPasswordReset.bind(this)}
                />
              }
              { action === 'resetPassword' &&
                <ResetPasswordForm
                  onSubmit={this.handlePasswordReset.bind(this)}
                  token={token}
                />
              }

              <div className='links'>
                <p>
                  { action === 'login'
                    ? <a href='/' onClick={e => { e.preventDefault(); this.setState({ action: 'requestPasswordReset' }) }}>Forgot Password?</a>
                    : <a href='/' onClick={e => { e.preventDefault(); this.setState({ action: 'login' }) }}>Let me login</a>
                  }
                </p>
                <p><small>Commit timestamp: 2025-01-02 12:48.</small></p>
              </div>
            </div>
            <Link to="/validity-check" className="btn btm-block btn-link">Validity Check</Link>
          </div>
        </div>
        {/* <footer className='footer'>&copy; 2014-2015 The Develovers</footer> */}
      </Outside>
    )
  }
}

function mapStateToProps (state) {
  return {
  }
}

function mapDispatchToProps (dispatch) {
  return {
    login: (data) => dispatch(login(data)),
    requestResetPassword: (data) => dispatch(requestResetPassword(data)),
    resetPassword: (data) => dispatch(resetPassword(data))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login))
