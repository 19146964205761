import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link, Route, withRouter } from "react-router-dom";

import { toggleSidebar, toggleSubMenu } from "../../actions/layout";
import { useExchangeToken } from "../../actions/auth";

export function LeftSideBar(props) {
  const {
    role,
    toggleSubMenu,
    openSubMenu,
    push,
    openSidebar,
    toggleSidebar,
    user,
  } = props;

  function goTo(id, to, event) {
    event.preventDefault();
    const topMenu = baseMenu.find((x) => x.id === id);
    if (topMenu?.children) {
      toggleSubMenu(id);
    } else {
      toggleSidebar();
      push(to);
    }
  }
  const exchange = useExchangeToken({
    token: user?.access_token,
    userId: user?.id,
  });

  const baseMenu = [
    {
      id: "questionnaires",
      title: "Questionnaires",
      icon: "fa fa-file-o",
      url: "/questionnaires",
      children: [
        {
          id: "newQuestionnaire",
          title: "New Questionnaire",
          url: "/questionnaires/new",
        },
        {
          id: "questionnaireList",
          title: "Questionnaire List",
          url: "/questionnaires",
        },
        {
          id: "quotationList",
          title: "Quotation List",
          url: "/quotations",
        },
        {
          id: "surveillanceList",
          title: "Surveillance List",
          url: "/quotation-schedules?phase=surveillance",
        },
        {
          id: "recertificationList",
          title: "Recertification List",
          url: "/quotation-schedules?phase=recertification",
        },
      ],
    },
    {
      id: "orders",
      title: "Orders",
      icon: "fa fa-file",
      url: "/orders",
      children: [
        {
          id: "orderList",
          title: "Order List",
          url: "/orders",
        },
        {
          id: "auditPlanList",
          title: "Audit Plan List",
          url: "/audit-plans",
        },
        {
          id: "auditReportList",
          title: "Audit Report List",
          url: "/audit-reports",
        },
        {
          id: "nonComplyList",
          title: "Non Conformity List",
          url: "/ncs",
        },
        {
          id: "certificateList",
          title: "Certificate List",
          url: "/certificates",
        },
        {
          id: "daList",
          title: "Daily Allowance List",
          url: "/daily-allowances",
        },
        {
          id: "daPaymentList",
          title: "Daily Allowance Payment List",
          url: "/daily-allowance-payments",
        },
      ],
    },
    {
      id: "invoices",
      title: "Invoices",
      icon: "fa fa-bank",
      url: "/invoices",
      children: [
        {
          id: "invoiceList",
          title: "Invoice List",
          url: "/invoices",
        },
      ],
    },
    {
      id: "scheduleMatrix",
      title: "Schedule Matrix",
      url: "/schedule-matrix",
      icon: "fa fa-table",
    },
    {
      id: "customers",
      title: "Customers",
      icon: "fa fa-user-circle",
      url: "/customers",
    },
    {
      id: "users",
      title: "Users",
      icon: "fa fa-group",
      url: "/users",
      children: [
        {
          id: "userList",
          title: "User List",
          url: "/users",
        },
        {
          id: "auditorExperts",
          title: "Auditor & Experts",
          url: "/users/auditor-experts",
        },
      ],
    },
    {
      id: "standards",
      title: "Standards",
      icon: "fa fa-book",
      url: "/standards",
    },
    {
      id: "settings",
      title: "Settings",
      icon: "fa fa-cogs",
      url: "/settings",
      children: [
        {
          id: "events",
          title: "Events",
          icon: "fa fa-calendar",
          url: "/events",
        },
        {
          id: "options",
          title: "Options",
          icon: "fa fa-cogs",
          url: "/options",
        },
      ],
    },
    {
      id: "advance-scheduling",
      title: "Advance Scheduling",
      icon: "fa fa-calendar",
      url: "/advance-scheduling",
    },
  ];
  const customerAccess = [
    "questionnaires",
    "questionnaireList",
    "quotationList",
    "surveillanceList",
    "recertificationList",
    "orders",
    "orderList",
    "auditPlanList",
    "auditReportList",
    "nonComplyList",
    "certificateList",
    "invoices",
    "invoiceList",
  ];
  const salesAccess = [
    "questionnaires",
    "newQuestionnaire",
    "questionnaireList",
    "quotationList",
    "surveillanceList",
    "recertificationList",
    "orders",
    "orderList",
    "auditPlanList",
    "certificateList",
    "customers",
    "scheduleMatrix",
  ];
  const salesManagerAccess = [
    "questionnaires",
    "newQuestionnaire",
    "questionnaireList",
    "quotationList",
    "surveillanceList",
    "recertificationList",
    "orders",
    "orderList",
    "auditPlanList",
    "certificateList",
    "customers",
    "scheduleMatrix",
  ];
  const applicationReviewerManagerAccess = [
    "questionnaires",
    "questionnaireList",
    "orders",
    "orderList",
    "auditPlanList",
    "auditReportList",
    "nonComplyList",
    "certificateList",
    "daList",
    // 'daPaymentList',
    "scheduleMatrix",
  ];
  const auditorAccess = [
    "questionnaires",
    "questionnaireList",
    "orders",
    "orderList",
    "auditPlanList",
    "auditReportList",
    "nonComplyList",
    "certificateList",
    "daList",
    // 'daPaymentList',
    "scheduleMatrix",
  ];
  const bodAccess = [
    "questionnaires",
    "questionnaireList",
    "quotationList",
    "surveillanceList",
    "recertificationList",
    "orders",
    "orderList",
    "scheduleMatrix",
  ];
  const schedulerAccess = [
    "questionnaires",
    "newQuestionnaire",
    "questionnaireList",
    "quotationList",
    "orders",
    "orderList",
    "scheduleMatrix",
    "advance-scheduling",
  ];
  const headOfCBAccess = [
    "questionnaires",
    "questionnaireList",
    "quotationList",
    "surveillanceList",
    "recertificationList",
    "orders",
    "orderList",
    "auditPlanList",
    "auditReportList",
    "nonComplyList",
    "certificateList",
    "daList",
    "daPaymentList",
    "scheduleMatrix",
  ];
  const deputyHeadOfCBAccess = [
    "questionnaires",
    "questionnaireList",
    "quotationList",
    "surveillanceList",
    "recertificationList",
    "orders",
    "orderList",
    "auditPlanList",
    "auditReportList",
    "nonComplyList",
    "certificateList",
    "daList",
    "daPaymentList",
    "scheduleMatrix",
  ];
  const auditPlanOperatorAccess = ["orders", "orderList", "auditPlanList"];
  const financeAccess = [
    "orders",
    "orderList",
    "daList",
    "daPaymentList",
    "invoices",
    "invoiceList",
  ];
  const certificateAdminAccess = ["orders", "orderList", "certificateList"];
  const superAdminAccess = [
    "questionnaires",
    "newQuestionnaire",
    "questionnaireList",
    "quotationList",
    "surveillanceList",
    "recertificationList",
    "orders",
    "orderList",
    "auditPlanList",
    "auditReportList",
    "nonComplyList",
    "certificateList",
    "daList",
    "daPaymentList",
    "invoices",
    "invoiceList",
    "scheduleMatrix",
    "customers",
    "users",
    "userList",
    "auditorExperts",
    "standards",
    "settings",
    "events",
    "options",
    "advance-scheduling",
  ];
  const adminSupportAccess = [
    "questionnaires",
    "newQuestionnaire",
    "questionnaireList",
    "quotationList",
    "surveillanceList",
    "recertificationList",
    "orders",
    "orderList",
    "auditPlanList",
    "auditReportList",
    "nonComplyList",
    "certificateList",
    "daList",
    "daPaymentList",
    "invoices",
    "invoiceList",
    "scheduleMatrix",
    "customers",
    "users",
    "userList",
    "auditorExperts",
    "standards",
    "settings",
    "events",
    "options",
  ];
  const surveillanceAdminAccess = [
    "questionnaires",
    "questionnaireList",
    "quotationList",
    "surveillanceList",
    "recertificationList",
    "orders",
    "orderList",
  ];

  let access = [];

  if (role.includes("Customer")) {
    access = [...access, ...customerAccess];
  }
  if (role.includes("Sales")) {
    access = [...access, ...salesAccess];
  }
  if (role.includes("Sales Manager")) {
    access = [...access, ...salesManagerAccess];
  }
  if (role.includes("Application Reviewer Coordinator")) {
    access = [...access, ...applicationReviewerManagerAccess];
  }
  if (role.includes("Auditor")) {
    access = [...access, ...auditorAccess];
  }
  if (role.includes("Board of Director")) {
    access = [...access, ...bodAccess];
  }
  if (role.includes("Scheduler")) {
    access = [...access, ...schedulerAccess];
  }
  if (role.includes("Head of CB")) {
    access = [...access, ...headOfCBAccess];
  }
  if (role.includes("Deputy Head of CB")) {
    access = [...access, ...deputyHeadOfCBAccess];
  }
  if (role.includes("Audit Plan Operator")) {
    access = [...access, ...auditPlanOperatorAccess];
  }
  if (role.includes("Super Admin")) {
    access = [...access, ...superAdminAccess];
  }
  if (role.includes("Admin Support")) {
    access = [...access, ...adminSupportAccess];
  }
  if (role.includes("Operational Manager")) {
    access = [...access, ...superAdminAccess];
  }
  if (role.includes("Finance")) {
    access = [...access, ...financeAccess];
  }
  if (role.includes("Certificate Admin")) {
    access = [...access, ...certificateAdminAccess];
  }
  if (role.includes("Surveillance Admin")) {
    access = [...access, ...surveillanceAdminAccess];
  }
  console.log({ user });
  return (
    <div
      id="left-sidebar"
      className={`left-sidebar ${
        props.openSidebar ? "sidebar-float-active" : "minified"
      }`}
    >
      {
        // <div className='sidebar-minified js-toggle-minified'>
        //   <i className='fa fa-exchange' onClick={props.toggleSidebar} />
        // </div>
      }
      <div className="sidebar-scroll">
        <nav className="main-nav">
          <ul className="main-menu">
            <Route
              path="/"
              exact
              children={(match) => (
                <li className={match.match ? "active " : ""}>
                  <Link to="/">
                    <i className="fa fa-dashboard" />
                    <span className="text"> Dashboard</span>
                  </Link>
                </li>
              )}
            />
            {baseMenu.map((x, i) => {
              if (access.includes(x.id)) {
                return (
                  <Route
                    key={i}
                    path={x.url}
                    exact={false}
                    children={(match) => (
                      <li className={match.match ? "active " : ""}>
                        {x.id == "advance-scheduling" ? (
                          <>
                            <div
                              className="advance-menu-hover"
                              onClick={exchange}
                              style={{
                                cursor: "pointer",
                                padding: "13px 0 13px 15px",
                                backgroundColor: "#144796",
                                color: "#fff",
                                borderTop: "1px solid #1a5dc6",
                              }}
                            >
                              <i className={x.icon} />
                              <span className="text">
                                {x.title}
                                {/* <span className='badge element-bg-color-blue'>New</span> */}
                              </span>
                            </div>
                          </>
                        ) : (
                          <a
                            className={`js-sub-menu-toggle ${x.title}`}
                            onClick={(e) => goTo(x.id, x.url, e)}
                            href="#top"
                          >
                            <i className={x.icon} />
                            <span className="text">
                              {" "}
                              {x.title}
                              {/* <span className='badge element-bg-color-blue'>New</span> */}
                            </span>
                            {x.children && (
                              <>
                                {openSubMenu === x.id ? (
                                  <i className="toggle-icon fa fa-angle-down" />
                                ) : (
                                  <i className="toggle-icon fa fa-angle-left" />
                                )}
                              </>
                            )}
                          </a>
                        )}

                        {x.children && (
                          <ul
                            className={
                              openSubMenu === x.id && openSidebar
                                ? "sub-menu open"
                                : "sub-menu"
                            }
                          >
                            {x.children.map((y, j) => {
                              if (access.includes(y.id)) {
                                return (
                                  <Route
                                    key={j}
                                    path={y.url}
                                    exact
                                    children={(match) => (
                                      <li
                                        className={match.match ? "active " : ""}
                                      >
                                        <a
                                          className={
                                            "link-" +
                                            y.title
                                              .toLowerCase()
                                              .split(" ")
                                              .join("-")
                                          }
                                          to={y.url}
                                          onClick={(e) => goTo(null, y.url, e)}
                                          href="#top"
                                        >
                                          <span className="text">
                                            {y.title}
                                          </span>
                                        </a>
                                      </li>
                                    )}
                                  />
                                );
                              } else {
                                return "";
                              }
                            })}
                          </ul>
                        )}
                      </li>
                    )}
                  />
                );
              }
              return "";
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.data.role,
    openSidebar: state.layout.openSidebar,
    openSubMenu: state.layout.openSubMenu,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    toggleSubMenu: (menu) => dispatch(toggleSubMenu(menu)),
    push: (to) => dispatch(push(to)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftSideBar)
);
